import ApiService from './api.service'

const TextPageService = {
  async create(data) {
    try {
      return await ApiService.post('textPages', data)
    } catch (error) {
      return error.response
    }
  },
  async textPages(page = 1) {
    try {
      return await ApiService.get('textPages?page=' + page)
    } catch (error) {
      return error.response
    }
  },
  async deleteTextPageById(slideId) {
    try {
      const response = await ApiService.delete('textPages/' + slideId)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async textPage(id) {
    try {
      return await ApiService.get('textPages/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },
  async updateTextPage(data, id) {
    try {
      return await ApiService.put('textPages/' + id, data)
    } catch (error) {
      return error.response
    }
  }
}

export { TextPageService }
