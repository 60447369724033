const management = [
  {
    path: 'text-pages',
    name: 'textpages',
    icon: 'cil-speech',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [
      {
        path: '',
        name: 'textpages_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/textpage/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      },
      {
        path: 'new',
        name: 'text_page_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/textpage/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      },
      {
        path: ':id',
        name: 'textpage',
        components: {
          content: () => import('@/views/pages/textpage/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      }
    ]
  },

  {
    path: 'sliders',
    name: 'sliders',
    icon: 'cil-task',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [
      {
        path: '',
        name: 'sliders_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/slider/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      },
      {
        path: 'new',
        name: 'slider_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/slider/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      },
      {
        path: ':id',
        name: 'slider',
        components: {
          content: () => import('@/views/pages/slider/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      }
    ]
  },

  {
    path: 'news-feeds',
    name: 'news_feed',
    icon: 'cil-chat-bubble',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['newsFeedGroup']
    },
    children: [
      {
        path: '',
        name: 'news_feed_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/news_feed/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['newsFeedGroup']
        }
      },
      {
        path: 'new',
        name: 'news_feed_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/news_feed/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['newsFeedGroup']
        }
      },
      {
        path: ':id',
        name: 'news_feed_edit',
        components: {
          content: () => import('@/views/pages/news_feed/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['newsFeedGroup']
        }
      }
    ]
  },

  {
    path: 'menus',
    name: 'frontend_menu',
    icon: 'cil-menu',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [
      {
        path: '',
        name: 'frontend_menu_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/frontend_menu/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion']
        }
      }
    ]
  },

  {
    path: 'media-pages',
    name: 'mediapages',
    icon: 'cil-speech',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de'],
      allowedTenant: ['mediaPagesGroup']
    },
    children: [
      {
        path: '',
        name: 'mediapages_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/mediapage/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['mediaPagesGroup']
        }
      },
      {
        path: 'media-files',
        name: 'mediapages_file_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/mediapage/FileList.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['mediaPagesGroup']
        }
      },
      {
        path: 'new',
        name: 'media_page_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/mediapage/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['mediaPagesGroup']
        }
      },
      {
        path: ':id',
        name: 'mediapage',
        components: {
          content: () => import('@/views/pages/mediapage/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['mediaPagesGroup']
        }
      }
    ]
  },

  {
    path: 'default-banners',
    name: 'default_banners',
    icon: 'cil-spreadsheet',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/banners/List.vue')
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'ch', 'nl'],
      allowedTenant: ['bannerGroup']
    }
  },

  {
    path: 'prices',
    name: 'prices',
    icon: 'cib-product-hunt',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['pricesGroup']
    },
    children: [
      {
        path: '',
        name: 'prices_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/price/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          allowedTenant: ['pricesGroup']
        }
      },
      {
        path: ':id',
        name: 'price',
        components: {
          content: () => import('@/views/pages/price/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          allowedTenant: ['pricesGroup']
        }
      },

      {
        path: 'new',
        name: 'price_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/price/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          allowedTenant: ['pricesGroup']
        }
      }
    ]
  },

  {
    path: 'price-categories',
    name: 'price_categories',
    icon: 'cib-product-hunt',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['ch', 'nl'],
      allowedTenant: ['priceCategoryGroup']
    },
    children: [
      {
        path: '',
        name: 'price_categories_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/price_categories/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['ch', 'nl'],
          allowedTenant: ['priceCategoryGroup']
        }
      },
      {
        path: ':id',
        name: 'price_category',
        components: {
          content: () => import('@/views/pages/price_categories/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['ch', 'nl'],
          allowedTenant: ['priceCategoryGroup']
        }
      },

      {
        path: 'new',
        name: 'price_categories_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/price_categories/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['ch', 'nl'],
          allowedTenant: ['priceCategoryGroup']
        }
      }
    ]
  },

  {
    path: 'brochure-prices',
    name: 'brochure_prices',
    icon: 'cib-product-hunt',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de'],
      allowedTenant: ['brochurePricesGroup']
    },
    children: [
      {
        path: '',
        name: 'brochure_prices_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/brochure_prices/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['brochurePricesGroup']
        }
      },
      {
        path: ':id',
        name: 'brochure_price',
        components: {
          content: () => import('@/views/pages/brochure_prices/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['brochurePricesGroup']
        }
      },
      {
        path: 'brochure-files',
        name: 'brochure_file_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/brochure_prices/FileList.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['brochurePricesGroup']
        }
      },
      {
        path: 'new',
        name: 'brochure_prices_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/brochure_prices/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de'],
          allowedTenant: ['brochurePricesGroup']
        }
      }
    ]
  },

  {
    path: 'main-boxes',
    name: 'main_boxes',
    icon: 'cib-product-hunt',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
      allowedTenant: ['mainBoxesGroup']
    },
    children: [
      {
        path: '',
        name: 'main_boxes_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/main_boxes/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
          allowedTenant: ['mainBoxesGroup']
        }
      },
      {
        path: ':id',
        name: 'main_box',
        components: {
          content: () => import('@/views/pages/main_boxes/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
          allowedTenant: ['mainBoxesGroup']
        }
      },

      {
        path: 'new',
        name: 'main_boxes_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/main_boxes/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
          allowedTenant: ['mainBoxesGroup']
        }
      }
    ]
  },

  {
    path: 'hello-customer-survey',
    name: 'hello-customer-survey',
    icon: 'cil-short-text',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['nl', 'en']
    },
    children: [
      {
        path: '',
        name: 'surveys_list',
        icon: 'cil-short-text',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/hello-customer-surveys/List.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['nl', 'en']
        }
      },

      {
        path: 'new',
        name: 'survey_new',
        icon: 'cil-short-text',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/hello-customer-surveys/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['nl', 'en']
        }
      },
      {
        path: ':id',
        name: 'survey',
        components: {
          content: () => import('@/views/pages/hello-customer-surveys/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['nl', 'en']
        }
      }
    ]
  },

  {
    path: 'about-list',
    name: 'about',
    icon: 'cil-speech',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['aboutPage']
    },
    children: [
      {
        path: '',
        name: 'about_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/about/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['aboutPage']
        }
      },
      {
        path: 'new',
        name: 'about_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/about/New.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['aboutPage']
        }
      },
      {
        path: ':id',
        name: 'about_edit',
        components: {
          content: () => import('@/views/pages/about/Edit.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['aboutPage']
        }
      }
    ]
  },
  {
    path: 'contact-service',
    name: 'contact_service',
    icon: 'cil-speech',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['contactService']
    },
    children: [
      {
        path: '',
        name: 'contact_service_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/contact_service/List.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['contactService']
        }
      },
      {
        path: 'new',
        name: 'contact_service_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/contact_service/New.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['contactService']
        }
      },
      {
        path: ':id',
        name: 'contact_service_edit',
        components: {
          content: () => import('@/views/pages/contact_service/Edit.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['contactService']
        }
      }
    ]
  },
  {
    path: 'promotions-list',
    name: 'promotions',
    icon: 'cil-speech',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['promotions']
    },
    children: [
      {
        path: '',
        name: 'promotions_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/promotions/List.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['promotions']
        }
      },
      {
        path: 'new',
        name: 'promotions_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/promotions/New.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['promotions']
        }
      },
      {
        path: ':id',
        name: 'promotions_edit',
        components: {
          content: () => import('@/views/pages/promotions/Edit.vue')
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['promotions']
        }
      }
    ]
  }
]

export default management
