const requests = [
  {
    path: 'product-trainings-request',
    name: 'product_trainings_request',
    icon: 'cil-short-text',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/online_trainings/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['trainings']
    }
  },
  {
    path: 'bridgestone-courses-request',
    name: 'bridgestone_courses_request',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/bridgestone_trainings/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['bTrainings']
    }
  },
  {
    path: 'external-trainings-request',
    name: 'external_trainings_request',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/external_trainings/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['externalTrainings']
    }
  },
  {
    path: 'investment-support-request',
    name: 'investments_request',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/investment_support/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['investSupport']
    }
  },
  {
    path: 'hunter-request',
    name: 'hunter_request',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/hunter/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['hunter']
    }
  },

  {
    path: 'sales-promotions',
    name: 'salespromotions',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['promotions']
    },
    children: [
      {
        path: '',
        name: 'salespromotions_list',
        components: {
          content: () => import('@/views/pages/requests/sales-promotions/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['promotions']
        }
      },
      {
        path: ':id',
        name: 'salespromotions_view',
        components: {
          content: () => import('@/views/pages/requests/sales-promotions/View.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['promotions']
        }
      }
    ]
  },

  {
    path: 'complaints-list',
    name: 'complaints',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['complaints']
    },
    children: [
      {
        path: '',
        name: 'complaints_list',
        components: {
          content: () => import('@/views/pages/complaints/List.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['complaints']
        }
      },
      {
        path: ':id',
        name: 'complaints_view',
        components: {
          content: () => import('@/views/pages/complaints/View.vue')
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['complaints']
        }
      }
    ]
  },

  {
    path: 'uberall-request',
    name: 'uberall_request',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/requests/uberall/List.vue')
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de'],
      allowedTenant: ['uberAll']
    }
  }
]

export default requests
