<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid class="flex justify-content-between" to="/">
      <CButton @click="setMenu" type="button">
        <CIcon icon="cil-menu"></CIcon>
      </CButton>
      <img :src="require('@/assets/bridgestone_logo.svg')" />
      <CButtonGroup role="group" aria-label="Basic example">
        <CButton color="primary" type="button" :title="$t('message.buttons.setMaintenance')"
          v-if="!adminUser.is_maintenance && isAdmin" @click="createMaintenance()" :disabled="loading">
          {{ $t('message.buttons.setMaintenance') }}
        </CButton>
        <CButton color="danger" type="button" :title="$t('message.buttons.hideMaintenance')"
          v-else-if="adminUser.is_maintenance && isAdmin" @click="deleteMaintenance()" :disabled="loading">
          {{ $t('message.buttons.hideMaintenance') }}
        </CButton>
        <CButton @click="logout" type="button" :title="$t('message.logout')" :disabled="loading">
          <CIcon icon="cil-account-logout"></CIcon>
        </CButton>
      </CButtonGroup>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>

    <CModal size="xl" :visible="showMaintenanceModal">
      <MaintananceInfo @close="closeMaintance($event)" />
    </CModal>
  </CHeader>
</template>

<script setup>
import AppBreadcrumb from './AppBreadcrumb'
import MaintananceInfo from '@/components/MaintananceInfo'
import { UserService } from '@/services/user.service'
import { AppService } from '@/services/app.service'
import store from '@/store'
import { ref, watch, computed } from 'vue'

const menuVisible = ref(true)
const showMaintenanceModal = ref(false)
const logout = async () => await UserService.logout(true)
let loading = computed(() => store.getters.getPending)

const roles = computed(() => store.getters.getAdminRole)
const isAdmin = computed(() => roles.value.find((item) => item.name == 'admin'))
const adminUser = computed(() => store.getters.getAdminMe)

const setMenu = () => {
  menuVisible.value = !menuVisible.value
  store.dispatch('setMenuVisible', menuVisible.value)
}

watch(
  () => store.getters.getMenuVisible,
  (newVal) => {
    menuVisible.value = newVal
  },
)

const createMaintenance = () => {
  showMaintenanceModal.value = true
}

const deleteMaintenance = async () => {
  await AppService.deleteMaintenance()
}

const closeMaintance = () => {
  showMaintenanceModal.value = false
}
</script>

<style scoped>
.flag {
  width: 100%;
  max-width: 20px;
}
</style>