export default {
  message: {
    expected_name: 'Expected name',
    exportDataTitle: 'Download lists as Excel file',
    forename: 'First name',
    lastname: 'Last name',
    mobile_phone: '(MOBILE) phone',
    commitment_letter_accept: 'Commitment letter accept',
    iban_number: 'IBAN-nummer',
    without_supplier: 'Without supplier',
    price_in_euro: 'Price in Euro',
    is_default: 'Default',
    attribute_option: 'attribute option',
    image: 'Image',
    show_stats_user: 'Shop Statistics - USER',
    is_manual_order: 'Is manual order',
    not_available: 'Currently not available at the time',
    supplier_id: 'Supplier ID',
    show_empty: 'Show products with quantity = 0',
    vendor_id: 'Manufacturer ID',
    archive_at: 'Archive',
    exported_bridgestone: 'Exported Bridgestone',
    exported_engelbert: 'Exported Engelbert Strauß',
    exported_prime: 'Exported prime',
    exported_other: 'Exported other',
    product_variant_id: 'Product variant id',
    cancelled_at: 'Cancelled at',
    shipped_at: 'Shipped at',
    product_id: 'Product id',
    quantity: 'Quantity',
    qty: 'Quantity',
    action_text: 'URL text',
    action_url: 'URL',
    token: 'UUID',
    marketing_option: 'Marketing option',
    parent_id: 'Parent ID',
    approval_id: 'Approval id',
    wholesaler_id: 'Wholesaler id',
    cart_id: 'Cart id',
    order_date: 'Order date',
    order_number: 'Order number',
    accept_date: 'Accept date',
    wholesaler_dealer_id: 'Wholesaler dealer id',
    dealer_id: 'Dealer id',
    is_partial_delivery: 'Is partial delivery',
    order_completed: 'Order completed',
    order_cancelled: 'Order cancelled',
    date_delivery: 'Delivery date',
    published: 'Publish',
    properties: 'Properties',
    group: 'Group name',
    gka: 'GKA',
    publish_at: 'Publish',
    video: 'Video url',
    training_id: 'Training ID',
    correct: 'Is correct?',
    question_id: 'Question ID',
    body: 'Content',
    size: 'Text size',
    platform: 'Operating system',
    is_added: 'Active Booster',
    numberOfSetsOfSeasonalTiresBudget: 'storage per year',
    distributionWinter: 'Winter',
    distributionSummer: 'Summer',
    overEighteenInch: 'Of which ≥ 18 inches (in %)',
    tireSalesNumber: 'Tire sales per year approx. in units.',
    job_position_id: 'Position in the company',
    distributionAll: 'All season',
    budgetPremium: 'Premium',
    postal_code: 'Postal code',
    legalForm: 'Legal form',
    budgetMid: 'Middle',
    budgetLow: 'Budget',
    group_id: 'Group ID',
    website: 'website',
    customer_number: 'Customer number',
    user_id: 'User ID',
    browser: 'web Browser',
    ip: 'Ip address',
    email_verified_at: 'email verified at',
    last_login: 'Last login',
    deleted_at: 'deleted at',
    is_active: 'Is active?',
    old: 'Old',
    selectBooster: 'Select booster',
    activeBooster: 'Active Booster',
    activeBoosterText: 'Do you want to active booster for user?',
    activeBoostersOne: 'Are you going to active this booster for every user?',
    sum: 'Sum',
    boosterOnTop: 'Booster Points (on Top)',
    relevantBoosterPoints: 'Relevant booster points',
    totalBefore: 'Points Total Before',
    standardPoints: 'Standard points',
    boosterPoints: 'Booster points',
    allPoints: 'All points',
    pointsByUser: 'Download points by user',
    pointsByGroup: 'Download points by group',
    loginAs: 'Login as user',
    positionX: 'Position x',
    positionY: 'Position y',
    textSize: 'Text size',
    text: 'Text',
    publish: 'Publish',
    certificate: 'Certificate',
    remove_cs_number: 'Are you sure you want to delete the customer number?',
    remove_order: 'Are you sure you want to delete the order?',
    is_internal: 'Is internal user?',
    selectAddressBtn: 'Select address',
    currentBasket: 'Current basket',
    loginEmail: 'Login',
    open_new_tab: 'Open in new tab?',
    file: 'File',
    company_name: 'Company name',
    partner_name: 'Contact person',
    boosterImport: {
      label: 'CSV File.'
    },
    event: 'Event',
    created_at: 'Created at',
    dealer: 'Dealer',
    dealers: 'Dealers',
    activity: 'Activity',
    logs: 'Logs',
    full_text_search: 'Full text search',
    booster_wholesaler: 'Wholesaler',
    booster_saison: 'Saison',
    booster_create: 'Create Booster',
    booster_tires: 'Tires',
    booster_products: 'Product names',
    booster_customers: 'Customer numbers',
    booster_ipcs: 'IPCs',
    booking: 'Booking',
    booster_factor: 'Multiply',
    select: 'Please select',
    preview: 'Preview',
    credit: 'Credit',
    deduction: 'Deduction',
    subject: 'Subject',
    customerNumber: 'Customer number',
    search: 'Search',
    customer_nr_name: 'Customer number / name',
    selectAll: 'Select all',
    import: 'Import',
    articleNumber: 'Article number',
    inform_user: 'To inform customers',
    leaveComment: 'Leave a comment here',
    user_new: 'New User',
    actions: 'Actions',
    residence: 'Is residence',
    emailVerified: 'E-Mail verified at',
    optinAt: 'OptIn at',
    registerAt: 'Register at',
    street: 'Street and number',
    country: 'Country',
    postalcode: 'Postcode',
    city: 'City',
    suffix: 'Address suffix',
    fax: 'Fax',
    date: 'Date',
    optinToken: 'OptIn-Token',
    title: 'Title',
    phone: 'Phone',
    company: 'Company',
    language: 'Language',
    salutation: 'Salutation',
    userData: 'Userdata',
    profile: 'Profile',
    addresses: 'Adresses',
    address: 'Address',
    vendor_new: 'New vendor',
    supplier_new: 'New supplier',
    show0Products: 'Show products with quantity = 0',
    notAvailable: 'Currently not available at the time',
    limitless: 'Limitless',
    isDefault: 'Is Default?',
    data: 'Data',
    startImport: 'Start import',
    importText: 'Please select a file for import:',
    upload: 'Upload',
    vat: 'VAT',
    uploadText: 'Upload an image',
    users: 'Users',
    save: 'Save',
    saving: 'Saving...',
    downloadOrderStats: 'Download stats',
    downloadProductStats: "Download product's stats",
    category: 'Category',
    order: 'Order',
    permissions: 'Permissions',
    permission: 'Permission',
    options: 'Options',
    attributes: 'Attributes',
    add_attribute: 'Add Attribute',
    login: 'Log in',
    logout: 'Logout',
    email: 'E-Mail',
    username: 'Username',
    type: 'Type',
    status: 'State',
    filter: 'Filter',
    name: 'Name',
    remove: 'Remove',
    points: 'Points',
    description: 'Description',
    short_description: 'Short Description',
    count: 'count',
    action: 'Action',
    role: 'Role',
    sku: 'SKU',
    main: 'Main product',
    variant: 'product variant',
    active: 'Active',
    vendor: 'Vendor',
    supplier: 'Supplier',
    images: 'Images',
    upload_text: 'Product-images upload',
    user: 'User',
    roles: 'Roles',
    search_products: 'Search products',
    search_orders: 'Search orders',
    search_type: 'Search type',
    hunter_new: 'New Hunter',
    first_name: 'First name',
    last_name: 'Last name',
    copy: 'Copy',
    add_empty_variant: 'Add Empty Variant',
    activated: 'Activated',
    contacts: {
      empty: 'No contacts'
    },
    menu: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      messages_new: 'New Message',
      messages_list: 'List',
      logs: 'Logs',
      activities: 'Activities',
      customer: 'Customer',
      booster_setup: 'Booster Setup',
      booster_list: 'List',
      booster: 'Booster',
      points: 'Point management',
      cheques: 'cheques / deductions',
      'users-roles': 'Roles & Users',
      roles: 'Roles',
      users: 'Users',
      users_list: 'Users List',
      users_deleted: 'Deleted Users',
      user_new: 'Add New User',
      perms: 'Permissions',
      'product-categories': 'Categories & Products',
      categories: 'Categories',
      categories_list: 'Categories List',
      category_new: 'Add New Category',
      orders: 'Orders',
      products: 'Products',
      products_list: 'Products List',
      product_new: 'Add New Single Product',
      product_new_variants: 'New Product with Variants',
      attributes: 'Attributes',
      attributes_list: 'Attributes List',
      attribute_new: 'Add New Attribute',
      options_list: 'Options List',
      options_new: 'Add New Option',
      options: 'Options',
      vendors: 'Vendors',
      vendors_list: 'Vendors List',
      vendor_new: 'Add New Vendor',
      suppliers: 'Suppliers',
      suppliers_list: 'Suppliers List',
      supplier_new: 'Add New Supplier',
      sliders: 'Sliders',
      sliders_list: 'Sliders List',
      slider_new: 'Add New Slider',
      featured: 'Featured Products',
      commands: 'Commands',
      command_ipc: 'IPC',
      command_users: 'Import User',
      'trainings-schulungen': 'Trainings & Lectures',
      trainings: 'Product Trainings',
      trainings_list: 'List',
      training_new: 'Add New',
      btrainings_list: 'List',
      btraining_new: 'Add New',
      btrainings: 'BS Trainings',
      management: 'Content Management',
      textpages: 'Text Pages',
      textpages_list: 'Text Pages List',
      text_page_new: 'Add new Text Page',
      settings: 'Messages manager',
      messages: 'Messages',
      threads: 'Threads',
      requests: 'Users requests',
      product_trainings_request: 'Product training requests',
      bridgestone_courses_request: 'Bridgestone courses requests',
      external_trainings_request: 'External trainings requests',
      hunter_request: 'Hunter',
      investments_request: 'Investment support',
      news_feed: 'Newsfeed',
      news_feed_list: 'News List',
      news_feed_new: 'Add new News',
      salespromotions: 'Sales Promotions',
      imported_products: 'Imported products',
      hunter_list: 'Hunter List',
      hunters: 'Hunters',
      hunter_new: 'New Hunter',
      groups_list: 'Groups List',
      parent_attributes: 'Parent Attributes',
      groups: 'Groups',
      group_new: 'New Group',
      history: 'Login history',
      history_list: 'Login History',
      prices: 'Prices',
      prices_list: 'List',
      price_new: 'New price',
      price_categories: 'Price lists',
      price_categories_list: 'List',
      price_categories_new: 'New price list',
      inactive_users: 'Not activated users',
      customer_numbers_to_approve: 'Neuanmeldungen<br/>ohne Nummer',
      complaints_list: 'Complaints',
      orders_list: 'List',
      orders_new: 'New order',
      points_list: 'Points list',
      kickback_list: 'Kickback fee',
      booster_preview: 'Preview',
      frontend_menu: 'Frontend menu',
      frontend_menu_list: 'List',
      frontend_menu_new: 'New',
      'products-categories': 'Products & categories',
      mediapages: 'Media Pages',
      mediapages_list: 'List',
      media_page_new: 'New',
      uberall_request: 'Uberall',
      history_hour: 'Login hours',
      wholesaler_management: 'Wholesaler management',
      mediapages_file_list: 'File list',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'List',
      brochure_prices: 'Product brochures & price lists',
      brochure_prices_new: 'New',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
      'hello-customer-survey': 'Hello Customer Survey',
      surveys_list: 'Surveys List',
      survey_new: 'Add New Survey',
      apps: 'Apps',
      apps_list: 'List of Apps',
      dealer_apps: 'Dealer apps',
      app_new: 'New',
      about_list: 'List',
      about_new: 'New',
      about: 'About page',
      about_edit: 'Edit',
      contact_service_list: 'List',
      contact_service_new: 'New',
      contact_service: 'Contact service page',
      contact_service_edit: 'Edit',
      promotions_edit: 'Edit',
      promotions_new: 'New',
      promotions_list: 'List',
      promotions: 'Promotions'
    },
    breadcrumb: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      activities: 'Activities',
      customer: 'Customer',
      booster_setup: 'Booster Setup',
      booster_list: 'List',
      booster: 'Booster',
      cheques: 'Cheques & Deductions',
      user_edit: 'Edit user',
      user_new: 'New User',
      vendors: 'Vendors',
      suppliers: 'Suppliers',
      users: 'Users',
      user: 'User',
      home: 'Home',
      perms: 'Permissions',
      roles: 'Roles',
      roles_list: 'List',
      dashboard: 'Dashboard',
      orders: 'Orders',
      orders_edit: 'Edit Order',
      orders_view: 'Order',
      orders_list: 'Orders',
      salespromotions: 'Sales promotion',
      salespromotions_list: 'List',
      salespromotions_view: 'View',
      'products-categories': 'Bonus Shop',
      categories: 'Categories',
      categories_list: 'List',
      role: 'Role',
      history: 'Login history',
      category: 'Category',
      products: 'Products',
      products_list: 'List',
      product: 'Product',
      product_new: 'New Single Product',
      product_new_variants: 'New Product with Variants',
      product_variants: 'Product with Variants',
      product_category: 'Category-Products',
      attributes: 'Attributes',
      attributes_list: 'List',
      attribute: 'Attribute',
      category_new: 'New Category',
      attribute_edit: 'Edit Attribute',
      options_new: 'New Option',
      options: 'Options',
      options_list: 'List',
      userEdit: 'Edit user',
      users_list: 'List',
      users_deleted: 'Deleted users',
      vendor_new: 'New Vendor',
      vendors_list: 'List',
      vendor_edit: 'Edit Vendor',
      supplier_new: 'New Supplier',
      suppliers_list: 'List',
      supplier_edit: 'Edit Supplier',
      trainings: 'Trainings',
      trainings_list: 'List',
      btrainings: 'Online-Trainings',
      btrainings_list: 'List',
      btraining: 'BS Training',
      training_new: 'New Training',
      btraining_new: 'New BS Training',
      training_edit: 'Edit Training',
      training: 'Training',
      command_ipc: 'IPC',
      sliders: 'Sliders',
      sliders_list: 'List',
      slider_new: 'New slider',
      slider: 'Edit slider',
      command_users: 'Import Users',
      textpages: 'Text pages',
      text_page_new: 'New Text page',
      textpage: 'Text page',
      textpages_list: 'List',
      messages: 'Messages',
      messages_list: 'List',
      messages_new: 'New Message',
      messages_edit: 'Edit message',
      threads: 'Threads',
      featured: 'Featured Products',
      attribute_new: 'New Attribute',
      product_trainings_request: 'Product trainings requests',
      bridgestone_courses_request: 'BS Training requests',
      external_trainings_request: 'External trainings requests',
      hunter_request: 'Hunter',
      investments_request: 'Investitionsunterstützung',
      news_feed: 'News feed',
      news_feed_list: 'Liste',
      news_feed_new: 'New News feed',
      news_feed_edit: 'Edit news feed',
      news_feed_slider: "New news feed's slider",
      imported_products: 'Imported products',
      hunter_list: 'List',
      hunters: 'Hunters',
      hunter_new: 'New Hunter',
      hunter_edit: 'Edit Hunter',
      groups: 'Groups',
      groups_list: 'Groups List',
      group_new: 'New Group',
      group_edit: 'Edit Group',
      history_list: 'Login history',
      history_view: 'User login history',
      prices: 'Prices',
      prices_list: 'List',
      price_new: 'New Price',
      price: 'Edit price',
      price_categories: 'Price lists',
      price_categories_list: 'List',
      price_categories_new: 'New price list',
      price_category: 'Price list',
      inactive_users: 'Not activated users',
      inactive_users_list: 'List',
      customer_numbers_to_approve: 'Neuanmeldungen ohne Nummer',
      complaints: 'Complaints',
      complaints_list: 'List',
      complaints_view: 'Complaint',
      orders_new: 'New order',
      points_list: 'Points list',
      booster_preview: 'Preview',
      frontend_menu: 'Frontend menu',
      frontend_menu_list: 'List',
      frontend_menu_new: 'New',
      kickback_list: 'Kickback fee',
      mediapages: 'Media Pages',
      mediapages_list: 'List',
      media_page_new: 'New',
      mediapage: 'Edit',
      uberall_request: 'Uberall',
      history_hour: 'Login hours',
      wholesaler_management: 'Wholesaler management',
      mediapages_file_list: 'File list',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'List',
      brochure_prices: 'Product brochures & price lists',
      brochure_prices_new: 'New',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
      'hello-customer-survey': 'Hello Customer Survey',
      surveys_list: 'Surveys List',
      survey_new: 'Add New Survey',
      survey: 'Survey',
      apps: 'Apps',
      apps_list: 'List of Apps',
      app_new: 'New',
      app: 'Edit',
      about_list: 'List',
      about_new: 'New',
      about: 'About page',
      about_edit: 'Edit',
      contact_service_list: 'List',
      contact_service_new: 'New',
      contact_service: 'Contact service page',
      contact_service_edit: 'Edit',
      promotions_edit: 'Edit',
      promotions_new: 'New',
      promotions_list: 'List',
      promotions: 'Promotions'
    },
    orders: {
      view: 'Order',
      new: 'New Order',
      list: 'Orders',
      edit: 'Edit Order',
      number: 'Order ID',
      date: 'Order Date',
      state: 'Order State',
      status: {
        created: 'Created',
        confirm_order: 'Confirm order',
        ready_for_shipment: 'Ready for shipment',
        rejected: 'Rejected',
        processed: 'Processed',
        shipped: 'Shipped',
        returned: 'Returned',
        finished: 'Finished'
      }
    },
    salespromotions: {
      view: 'Sales Promotion',
      list: 'Sales Promotions',
      premium: 'DRIVEOURBEST',
      guarantee: 'TYREPROTECT',
      bonusCampaign: 'REIFENHITS',
      advantage: 'VORTEILSAKTION'
    },
    countries: {
      de: 'Germany',
      at: 'Austria',
      ch: 'Switzerland',
      nl: 'Netherlands',
      fr: 'France',
      be: 'Belgium',
      fi: 'Finland',
      se: 'Sweden',
      dk: 'Denmark',
      pt: 'Portugal',
      es: 'Spain'
    },
    messages: {
      list: 'Messages',
      new: 'New Message',
      edit: 'Edit Message',
      title: 'Title',
      content: 'Content',
      empty: 'No messages',
      direct: 'Direct message',
      toDealers: 'To Dealers',
      sendEmail: 'Send email',
      selectWholesalers: 'Select wholesalers',
      directWholesaler: 'From wholesalers to dealers',
      saveAndSend: 'Save and send',
      users: 'Select users (min. 3 characters)',
      sent: 'Sent',
      externalTrainingPoints: 'External Training / Investment Points',
      externalTrainingBank: 'External Training / Investment Account Owner',
      externalTrainingIBAN: 'External Training / Investment IBAN',
      onlineTrainingTitle: 'Product Training - Title',
      onlineTrainingPoints: 'Online Training - Points',
      hunterTitle: 'Hunter - Title',
      hunterPoints: 'Hunter - Points',
      hunterPrice: 'Hunter - Price',
      bTrainingTitle: 'BS Training - Title',
      bTrainingPoints: 'BS v - Points',
      pointsProfileComplete: 'Points - Profile Completed',
      orderProducts: 'Products in the order'
    },
    messages_alert: {
      title: 'Delete Message',
      desc: 'Do you really want to delete the message?'
    },
    groups_alert: {
      title: 'Delete Group',
      desc: 'Do you really want to delete the group?'
    },
    vendors_alert: {
      title: 'Delete Manufacturer',
      desc: 'Do you really want to delete the manufacturer?'
    },
    suppliers_alert: {
      title: 'Delete Supplier',
      desc: 'Do you really want to delete the supplier?'
    },
    threads: {
      title: 'Threads',
      choose: 'Please choose message',
      order: 'Choose thread for orders',
      salespromotions: 'Choose thread for sales promotions',
      externalTraining: 'Choose thread for external trainings',
      investment: 'Choose thread for investment',
      onlineTraining: 'Choose thread for product training',
      bTraining: 'Choose thread for BS Training',
      profileComplete: 'Choose thread for Profile complete',
      activeUser: 'Active user message'
    },
    userGroup: {
      parentAttributeName: 'Parent Attribute',
      name: 'Group name',
      gka: 'GKA',
      isMain: 'Is parent?'
    },
    categories: 'Categories',
    count_users: 'Count users',
    count_perms: 'Count permissions',
    count_products: 'Count products',
    count_options: 'Count Options',
    product: 'Product',
    attribute_new: 'New Attribute',
    product_new: 'New Single Product',
    product_new_variants: 'New Product with Variants',
    training_new: 'New Training',
    btraining_new: 'New BS Training',
    category_new: 'New Category',
    category_title: 'Category Title',
    category_desc: 'Category Description',
    category_image: 'Category Image',
    category_link: 'Category Link',
    category_alert: {
      title: 'Delete Category',
      desc: 'Do you really want to delete the category?'
    },
    product_alert: {
      title: 'Delete Product',
      desc: 'Do you really want to delete the product?'
    },
    survey_alert: {
      title: 'Delete Survey',
      desc: 'Do you really want to delete the survey?'
    },
    cancel: 'Cancel',
    confirm: 'Confirm',
    training_edit: 'Edit Training',
    options_new: 'New Option',
    options_edit: 'Edit Option',
    subcategory: 'Subcategory',
    importStarted: 'Import was started',
    connection: 'Connection',
    variants: 'Variants',
    add_variant: 'Add Variant',
    vendors: 'Vendors',
    suppliers: 'Vendors',
    training: 'Training',
    commands: {
      ipc: 'IPC-Importer',
      users: 'Users Importer'
    },
    userImport: {
      label: 'XLSX type only'
    },
    questions: 'Questions',
    correctAnswer: 'Correct answer',
    validation: {
      required: 'Field is required'
    },
    edit_question: 'Edit questions',
    edit_btraining_Detail: 'Edit BS Training detail',
    video_url: 'Video url',
    save_new_question: 'Save new question',
    reward: 'Reward image - not required',
    poster: 'Poster image',
    season: 'Season',
    answers: 'Answers',
    training_details: 'Training details',
    extras: 'Additional information',
    duration: 'Duration',
    price: 'Price',
    min_participant: 'Minimum participants',
    max_participant: 'Maximum participants',
    choose_trainingType: 'Choose training type',
    update: 'Update',
    cost_type: 'Cost type',
    save_detail: 'Save detail',
    slider_new: 'New slider',
    sliders: 'Sliders',
    groups: 'Group',
    clientType: 'Client Type',
    slider: 'Edit slider',
    choose_showHideText: 'Show/hide text',
    text_page_new: 'New Text page',
    text_pages: 'Text pages',
    text_page: 'Text page',
    featured: {
      title: 'Featured Products',
      bestseller: 'Best sold',
      seasonal: 'Seasonal',
      affordable: 'Affordable',
      autoBestsellers: 'Show auto calculated bestsellers',
      affordableOffsetLabel: 'Max price offset',
      affordableLimitLabel: 'Display products limit'
    },
    seasons: {
      all: 'All Season',
      summer: 'Summer',
      winter: 'Winter',
      general: 'General'
    },
    addAddress: 'Add adress',
    administration: 'Administration',
    password: 'Password',
    toastMessages: {
      success: 'Operation has been accomplished with success',
      error: 'Unexpected error, please try again later.',
      invalid: 'Invalid values in form',
      done: 'Done!',
      requestProcessed: 'The request is being processed. One moment please...',
      noQuestions: 'Training must contain at least 1 question',
      pointsAdded: 'Booking made',
      selectUser: 'Select user from list',
      selectOneField: 'Select one field',
      pointRemoveSoon: 'Points will be deleted soon',
      dataLoading: 'Data is loading, please wait',
      selectDealerGroup: 'Select dealer group'
    },
    stats: {
      users_list: 'Count of users',
      external_trainings_request: 'External Trainings',
      product_trainings_request: 'Product trainings',
      bridgestone_courses_request: 'BS Training',
      hunter_request: 'Hunter',
      investments_request: 'Investment support',
      orders_list: 'Order list',
      history_list: 'Login history',
      salespromotions_list: 'Sales promotions',
      complaints_list: 'Complaints',
      points_list: 'Points list',
      kickback_list: 'Kickback fee',
      history_hour: 'Login hours',
      tyre_list: 'Tyres'
    },
    product_trainings_request: 'Product trainings requests',
    bridgestone_courses_request: 'BS Training requests',
    attempt_number: 'Attempt number',
    thirdPartyInvoice: 'Third party invoice',
    userInvoice: 'User invoice',
    bankAccountOwner: 'Bank account owner',
    open: 'Open',
    appLogo: 'Application Logo',
    appURL: 'Application URL',
    appURLSSOLogin: 'URL after SSO login',
    url: 'URL',
    url_text: 'URL text',
    choose_posterSide: 'Image side: ',
    left: 'Left',
    right: 'Right',
    disclaimer: 'Disclaimer',
    supply: {
      history: 'Supply history',
      quantity: 'Quantity'
    },
    copyAsMain: 'Copy as main teaser',
    news_feed_slider_list: "News feed slider's list",
    usersPage: {
      boostersToApprove: 'Booster to approve',
      lastLogin: 'Last Login:',
      customerNumbers: 'Customer number(s)',
      branches: 'Branch(es)',
      balance: 'Account balance',
      orders: 'Orders',
      groups: 'Groups',
      notes: 'Notes',
      salesStaff: 'Sales staff',
      parentBranch: 'Parent Branch',
      childBranch: 'Subbranch',
      addWholesaler: 'Add Wholesaler',
      addBranch: 'Add Branch',
      tooltip: 'To add a branch, you must save the customer number of the parent branch',
      tooltipRmCnLocal: 'Remove local customer number',
      tooltipRmCnDB: 'Remove customer number for database',
      statement: 'Bank statement',
      bank: {
        date: 'Date',
        supplySourceReason: 'Source of supply / Reason',
        customerNumber: 'Customer Nr.',
        article: 'Article',
        brand: 'Brand',
        product: 'Product',
        type: 'Type',
        inch: 'Inch',
        season: 'Season',
        amount: 'Amount',
        otherActions: 'Other action or cause',
        totalPoints: 'Points total',
        loadMore: 'Load 20 more records',
        seasons: {
          all: 'All Season',
          summer: 'Summer',
          winter: 'Winter',
          general: 'General'
        },
        advanced: 'Advanced',
        deleteUser: 'Delete User'
      },
      advanced: 'Advanced',
      deleteUser: 'Delete user',
      deleteUserConfirm: 'Are you sure you want to delete the user?',
      selectRole: 'Select Role',
      selectWholesaler: 'Select Wholesaler'
    },
    recoverUser: 'Retrieve user',
    pieces_per_set: 'Packaging units',
    new: 'New',
    all: 'All',
    showAddress: 'Show address',
    showGroup: 'Show Group',
    showPoints: 'Show points',
    showKickbacks: 'Show kickback fee',
    external_sales: 'External sales',
    login_from: 'Last Login (from)',
    login_to: 'Last Login (to)',
    check: 'Check',
    reject: 'Reject',
    reason: 'Reason',
    active_by: 'Activated by',
    sharePassword: 'Share this password with new admin',
    activeBtn: 'Activate now',
    newUserVerify: 'Verification of the new registration',
    complaintForm: {
      sections: {
        general: {
          title: 'generally',
          ordinance: 'Ordinance of the dealer',
          generalEmail: 'Your email address',
          country: 'Country',
          pickupLocation: 'Pickup location'
        },
        regulator: {
          title: 'address tire dealer - regulator'
        },
        vehicleDetails: {
          title: 'Vehicle details',
          vehicleTyres: 'vehicle/tyres',
          vehicleManufacturer: 'Vehicle manufacturer',
          vehicleModelType: 'Vehicle model and type designation according to approval',
          firstRegistration: 'First registration',
          enginePower: 'Engine power (KW e.g. 150)',
          originalEquipment: 'original equipment',
          tireMilageHours: 'Tire mileage (km) / operating hours',
          milageOrHours: 'km or hours (e.g. 2000)'
        },
        tyre: {
          tirePosition: 'Tyre position',
          tireSizeExample: 'Tire size (e.g. 215/60/16)',
          speedLoadIndex: 'Speed / Load Index',
          profileDesignation: 'Profile designation inc. Specification',
          dot: 'DOT',
          truckSerialNo: 'serial no. (only for truck tires)',
          complaintReason: 'Complaint reasons',
          isTyreReplaced: 'Have the tires already been replaced?',
          ifYesTyre: 'If yes, brand of spare tires',
          clerkName: 'Name of clerk',
          complaintReasonText: 'Complaint text'
        }
      }
    },
    yesText: 'Yes',
    noText: 'No',
    tyre: 'Tyre',
    buttons: {
      close: 'Close',
      placeOrder: 'Binding order',
      download: 'Download',
      approve: 'Approve',
      deleteOrder: 'Delete order',
      archive: 'Archive',
      acceptAll: 'Accept all',
      advanceSearch: 'Advanced search',
      addMenu: 'Add menu',
      setMaintenance: 'Set maintenance',
      hideMaintenance: 'Hide maintenance',
      deactivate: 'Deactivate',
      importSelected: 'Import selected',
      clearFilters: 'Clear filters',
      toSearch: 'Search',
      delete: 'Delete'
    },
    others: 'Others',
    archive_training: 'Do you really want to archive this training?',
    delete_menu: 'Sure to delte menu item?',
    no_entries: 'No entries',
    external_url: 'External URL',
    incomplete_address: 'Address is incomplete. Please check',
    request_from: 'Request from',
    let_see_points: 'Let user see marketing points',
    let_see_kickback: 'Let user see kickback fee',
    major: 'Major',
    media_category: 'Media category',
    dragHere: 'Drop the files here ...',
    addEmbbeded: 'Add embedded code',
    canReceivePoints: 'Can receive points',
    canUsePoints: 'Can use points',
    wholesaler_management: {
      modal: {
        description: 'What data can wholesaler see in dealer details?'
      }
    },
    personalData: 'Personal data',
    operatingData: 'Operating data',
    orderDelivery: 'Shipping address',
    diffDeliveryAddres: 'Add delivery address',
    status_iban: 'Status IBAN',
    iban: 'IBAN',
    show_export_user_iban: 'Users Dealer Iban',
    do_match: 'Change to Match',
    iban_status: {
      nameMatchResult: {
        MATCH: 'when the provided name matches the value of the account holder name held by the source',
        CLOSE_MATCH: '<b>when the provided name closely resembles the value of the account holder name held by the source</b>',
        NO_MATCH: '<b>when the provided name does not match the value of the account holder name held by the source</b>',
        COULD_NOT_MATCH: '<b>when the provided name could not be matched against the source data</b>',
        NAME_TOO_SHORT:
          '<b>when the provided name is too short to perform a match against the value of the account holder name held by the source</b>'
      },
      dataUsedForMatching: {
        VERIFIED: 'Verified is the data that exists at the beneficiary bank',
        DERIVED: '<b>Derived is data based on historical transactions</b>'
      },
      accountNumberValidation: {
        VALID: 'An account that conforms to the standards, e.g. a valid Mod97 calculation for an IBAN',
        NOT_VALID: '<b>account is an account that does not conform to the standards</b>'
      },
      paymentPreValidation: {
        PASS: 'is returned when the account identification was successfully validated to an account that can receive funds',
        WILL_FAIL: '<b>is returned if the payment will definitely fail</b>',
        WARNING:
          '<b>is returned in case the account identification was not successfully validated to an account that can receive funds, however, the responding bank is unable to provide a definitive answer</b>'
      },
      status: {
        ACTIVE: 'Account is a valid account and supported for checks',
        INACTIVE:
          '<b>Account is a valid account marked as inactive by the account holding bank, by a third party or by SurePay if SurePay has reason to believe that the account should be marked as inactive</b>',
        NOT_SUPPORTED: '<b>Account status stands for an account that is valid but is not supported to perform any checks</b>',
        NOT_FOUND:
          '<b>Account status stands for an account that is valid but could not be found in any of the connected data sources</b>',
        UNKNOWN: '<b>Account status is for an account that is either found as part of DERIVED data or a NOT_VALID account</b>'
      }
    },
    current_tyres_amount: 'Tyre amount',
    current_points: 'Score',
    current_kickbacks: 'Kick-back',
    change_role_info:
      'Changing a role in the <b>Bridgestone PLUS-System</b> deletes connections that are associated with a given role, e.g. points, customer numbers, connections between a Bridgestone processor and a dealer, orders, etc. are deleted. Are you sure you want to make this change because there is no way back?',
    firstRegistration: 'First registration',
    mediaCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      summer: 'Sommer',
      winter: 'Winter',
      all_season: 'All Season',
      promotions: 'Aktionen',
      video_in_store: 'Video (Instore)',
      video_online: 'Video (Online)',
      banner_website: 'Banner (Webseite)',
      banner_social_media: 'Banner (Social Media)',
      logos: 'Logos',
      b_drive: 'B-drive',
      media_library: 'Media library',
      media_packages: 'Media packages'
    },
    deleteMediaModal: {
      title: 'Delete media collection',
      description: "Do you confirm delete file's collection? It will delete all connect files."
    },
    deleteMediaFileModal: {
      title: 'Delete file from collection',
      description: 'Do you want to you delete file from this collection?'
    },
    languages: {
      de: 'German',
      ch: 'German',
      fr: 'French',
      en: 'English',
      nl: 'Dutch',
      it: 'Italian',
      es: 'Spanish',
      pt: 'Portuguese',
      da: 'Danish',
      se: 'Swedish',
      at: 'German',
      fi: 'Finnish',
      ro: 'Romanian',
      pl: 'Polish',
      cz: 'Czech',
      sk: 'Slovak',
      hu: 'Hungarian',
      ie: 'Interliguan'
    },
    colleague: 'colleague',
    tradeInDate: 'Trade in date',
    vouchers: {
      points: 'Number of points per voucher',
      amount: 'Number of vouchers'
    },
    selectText: 'Select',
    brochureCategories: {
      products: 'Products',
      brand_and_solutions: 'Brand & Solutions',
      season_and_promotions: 'Season & Promotions',
      format: 'Format',
      cars_and_vans: 'Cars & Vans',
      truck_and_buses: 'Trucks & Buses',
      motorcycle: 'Motorcycle',
      agriculture: 'Agriculture',
      construction_machinery: 'Construction Machinery',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      material: 'Materials',
      brochures: 'Brochures',
      price_list: 'Price List',
      materials: 'Materials'
    },
    survey: {
      new: 'New Survey',
      edit: 'Edit Survey',
      name: 'Name',
      title: 'Title',
      description: 'Description',
      url: 'Url of Hello Customer Stand-alone Survey',
      return_url: 'Return link to insert in Hello Customer Backend',
      user_email: 'Email',
      user_first_name: 'First Name',
      user_last_name: 'Last Name',
      user_gender: 'Gender',
      user_customer_number: 'Customer Number',
      user_whs: 'Whs',
      user_zip_code: 'Zip Code',
      has_rewards: 'Reward Survey participation',
      user_information: 'Selection of user information to be transmitted to Hello Customer',
      points: 'points',
      surveys_activated: 'Activated Surveys',
      select_surveys_activated: 'Select an activated survey'
    },
    deleteMainBox: {
      title: 'Delete main box',
      description: 'Are you sure you would like to delete this item?'
    },
    apps: {
      app_new: 'New App',
      title: 'Applications',
      name: 'Name',
      code: 'Code',
      ownerName: 'Owner Name',
      isVisibleEveryone: 'V2E',
      isSSOReady: 'SSO',
      relationDirect: 'Direct',
      relationInDirect: 'Indirect',
      local_app: 'Local',
      managed_globally_info: 'This application is managed by the Global Admin',
      managed_globally_disabled: 'The Global Admin has disabled this application for your country',
      app_alert: {
        title: 'Confirm',
        desc: 'Are you sure you want to remove this local application?'
      },
      app_change_state_alert: {
        title: 'Confirm',
        desc: 'This application is managed by global admin. Do you want to change running status of this app?'
      }
    },

    bsPlus: {
      buttons: {
        appButtons: {
          pending: 'Pending',
          open: 'Open',
          requestAccess: 'Request access'
        }
      },
      homepage: {
        my: 'Apps',
        titleColorRev: 'false',
        welcomeMessage: "Welcome to BRIDGESTONE <span class='text-danger'>PLUS</span>, %{first_name} %{last_name}",
        showProfile: 'Show Profile',
        newMessages: 'New messages in your inbox',
        directRoute: 'Your direct route to all Bridgestone web applications.',
        myAppsDescription: 'all Bridgestone web applications for which you are already a member',
        allAppsDescription: 'All Apps - All the Bridgestone web applications that may be of interest to you.',
        home: 'Home',
        search: 'Search',
        sortBy: 'Sort by',
        myApps: 'My Apps',
        allApps: 'All Apps',
        inbox: 'Inbox',
        profile: 'Profile',
        seeMore: 'See more',
        showMessages: 'Show messages'
      },
      myProfile: {
        welcomeMessage: 'Welcome to your personal area. Here, you can update or adjust your master data and view your inbox.',
        masterData: 'Master Data',
        completeDetails: 'Complete your personal and company details for a successful registration.',
        goToMasterData: 'Go to Master Data',
        goToInbox: 'Go to inbox'
      },
      masterData: {
        welcomeMessage: 'Welcome to your personal area. Here you can update or adjust your master data.',
        personalDetails: 'Personal details',
        companyDetails: 'Company details',
        emailAddress: 'Email address',
        title: 'Title',
        surname: 'Surname',
        firstName: 'First name',
        jobPosition: 'Job position',
        streetAndHouseNumber: 'Street & house number',
        postalCode: 'Postal code',
        city: 'City',
        country: 'Country',
        legalStructure: 'Legal structure',
        customerNumber: 'Customer number',
        importantPleaseRead: 'Important please read',
        customerNumberInfo:
          'To properly assign your tire orders to wholesalers and/or Bridgestone, it is essential that you provide your respective customer numbers.',
        multipleCustomerNumbers:
          'If you have multiple customer numbers with a wholesaler and/or Bridgestone, for example, for your main business and your branches, please provide the customer number for the main business first.',
        salesAssignment: 'The sales associated with the additional customer numbers will then be assigned to your main business.',
        supplier: 'Supplier',
        addAnotherCustomerNumber: 'Add another Bridgestone customer number',
        save: 'Save',
        delete: 'Delete'
      },
      inbox: {
        welcomeMessage: 'Here you can find important notifications and news.',
        sender: 'Sender',
        subject: 'Subject',
        date: 'Date',
        formConsulting: 'Form Consulting - Thank you for processing the form',
        back: 'Back'
      },
      legalAndContact: {
        cookieSettings: 'Cookie-settings',
        dataPolicy: 'Data policy',
        termsOfUse: 'Terms of use',
        helpAndContact: 'Help and contact'
      },
      logInSignUp: {
        logIn: 'Log in',
        signUp: 'Sign up',
        logInMessage: 'Log in with your email address and password.',
        signUpMessage: 'If you are a new user, please register.'
      },
      onboarding: {
        tradingPartner: 'I am a trading partner that is',
        termsAndConditions: 'Terms and Conditions',
        accept: 'Accept',
        decline: 'Decline',
        scrollToAccept: 'Please scroll down for accepting our conditions to continue',
        welcomeMessage: 'Welcome to Bridgestone Plus',
        dataModal:
          'In order to be able to adapt this offer to your needs, we ask you to kindly identify yourself and indicate your interests in terms of products or sectors of activity.',
        tailorOffer:
          'In order to be able to tailor this offer to your interests, we would ask you to briefly state your product interests or business areas.',
        selectAreas: 'Please select* from the following areas',
        multipleAnswers: '(multiple answers possible)',
        personalPreferences: 'Personal Preferences',
        carTyres: 'Car tyres, SUV, utility',
        motorcyclesTyres: 'Motorcycles tyres',
        truckBusTyres: 'Truck and bus tyres',
        agricultureTyres: 'Tyres for agricultural vehicles',
        otrTyres: 'OTR tyres',
        save: 'Save',
        businessRelationship: 'What is your business relationship with Bridgestone?',
        selectRelationship: 'Please select* from the following areas',
        tradingPartnerDirect: '... directly ordering from Bridgestone',
        tradingPartnerIndirect: '... indirectly ordering (via wholesale e.g.)',
        tradingPartnerBoth: '... both directly and indirectly ordering',
        fleetResponsibleDirect: 'I am a fleet responsible that is... directly ordering from Bridgestone',
        fleetResponsibleIndirect: '... indirectly ordering (via trade or wholesale)',
        fleetResponsibleBoth: '... directly and indirectly ordering',
        noBusinessRelationship: 'I have no business relationship with Bridgestone (neither directly nor indirectly)',
        yourCustomerNumber: 'Your Bridgestone customer number*: ',
        enterCustomerNumber: 'Enter your customer number',
        waitPersonalisedView: 'Please wait a moment, your personalised view of BRIDGESTONE PLUS will be generated.',
        personalised: 'Personalised',
        plusInfo: 'Here you will find information about your Plus account and your Plus mailbox.',
        continue: 'Continue',
        skip: 'Skip',
        oneOfTwo: '1 of 2',
        openApps:
          'You can directly open the apps you are already registered for. You can also request access to other apps that may be relevant to you.',
        requiredFields: 'Fill in the required fields: Title, First name, Last name',
        stage: 'Part'
      }
    },
    deleteMediaModal: {
      title: 'Delete media collection',
      description: "Do you confirm delete file's collection? It will delete all connect files."
    },
    deleteMediaFileModal: {
      title: 'Delete file from collection',
      description: 'Do you want to you delete file from this collection?'
    },
    anyLogin: 'Show users with login only',
    frontend_menu: {
      account: 'Profile',
      plus: 'About Bridgestone Plus',
      salesSupport: 'Sales support',
      mailbox: 'Mailbox',
      about: 'About Bridgestone Plus',
      trainingAndCourses: 'Online tranings',
      externalTraining: 'External trainings',
      productInfo: 'Product informations',
      productBrochures: 'Brochures',
      priceLists: 'Price list',
      price: 'Price',
      complaintForm: 'Complaint form',
      sales_support: 'Sale support',
      contact_service: 'Contact & Service',
      logOut: 'Logout',
      news: 'News feeds',
      news_feeds: 'News feeds',
      service_number: 'Contact & Service'
    }
  }
}
