const users = [
  {
    path: 'users',
    name: 'users',
    icon: 'cil-user',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion'],
    },
    children: [
      {
        path: '',
        name: 'users_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/user/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: ':id',
        name: 'user_edit',
        components: {
          content: () => import('@/views/pages/user/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: 'new',
        name: 'user_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/user/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: 'deleted',
        name: 'users_deleted',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/user/DeletedList.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: 'inactive-users',
        name: 'inactive_users',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/inactive_users/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: 'customer-numbers-to-approve',
        name: 'customer_numbers_to_approve',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/cs-numbers-to-approve/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          allowedTenant: ['fullVersion'],
        },
      },
      {
        path: 'points-list',
        name: 'points_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/points/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: 'kickback-list',
        name: 'kickback_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/points/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['nl'],
        },
      },
      {
        path: 'in-active-cs-nr',
        name: 'in_active_cs_nr',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/in_active_cs_nr/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: 'shop-limits',
        name: 'shopLimits',
        icon: 'cil-vector',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/shop_limits/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'ch'],
        },
      },
    ],
  },
  {
    path: 'permissions',
    name: 'perms',
    icon: 'cil-vector',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/Permissions.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion'],
    },
  },
  {
    path: 'wholesaler_management',
    name: 'wholesaler_management',
    icon: 'cil-group',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/wholesaler_management/List.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion'],
    },
  },

  {
    path: 'groups',
    name: 'groups',
    icon: 'cil-group',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion'],
    },
    children: [
      {
        path: '',
        name: 'groups_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/groups/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: ':id',
        name: 'group_edit',
        components: {
          content: () => import('@/views/pages/groups/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        },
      },
      {
        path: 'new',
        name: 'group_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/groups/New.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion', 'liteVersion'],
        }
      }
    ]
  },

  {
    path: 'login-history',
    name: 'history',
    icon: 'cil-group',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion'],
    },
    children: [
      {
        path: '',
        name: 'history_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/login_history/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion'],
        },
      },
      {
        path: '',
        name: 'history_hour',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/login_history/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion'],
        },
      },
      {
        path: ':id',
        name: 'history_view',
        components: {
          content: () => import('@/views/pages/login_history/View.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
          allowedTenant: ['fullVersion'],
        },
      },
    ],
  },
]

export default users
