import ApiService from './api.service'

const AboutService = {
  async create(data) {
    try {
      return await ApiService.post('admin/about-page', data)
    } catch (error) {
      return error.response
    }
  },
  async list(page = 1) {
    try {
      return await ApiService.get('admin/about-pages?page=' + page)
    } catch (error) {
      return error.response
    }
  },
  async show(id) {
    try {
      return await ApiService.get(`admin/about-pages/${id}/show`)
    } catch (error) {
      return error.response
    }
  },
  async delete(id) {
    try {
      return await ApiService.delete(`admin/about-pages/${id}`)
    } catch (error) {
      return error.response.data
    }
  },
  async update(data) {
    try {
      return await ApiService.put(`admin/about-pages/${data.id}`, data)
    } catch (error) {
      return error.response
    }
  },
  async reorder(data) {
    try {
      return await ApiService.post('admin/about-pages/reorder', data)
    } catch (error) {
      return error
    }
  },
  async uploadImages(data) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      let form = new FormData()
      form.append('file', data.blob(), data.filename())
      return await ApiService.post('admin/about-pages/images', form)
    } catch (error) {
      return error.response
    }
  }
}

export { AboutService }
