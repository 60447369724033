const getters = {
  getRoles: (state) => state.roles,
  getRole: (state) => state.role,
  getPermissionsForSelect: (state) => state.permissionsSelect,
  getUsers: (state) => state.users,
  getMe: (state) => state.user,
  getAdminMe: (state) => state.user_admin,
  getPermissions: (state) => state.permissions,
  getFilter: (state) => state.filter,
  getCategories: (state) => state.categories,
  getApps: (state) => state.apps,
  getMessages: (state) => state.messages,
  getMessage: (state) => state.message,
  getThreads: (state) => state.threads,
  getOrders: (state) => state.orders,
  getOrder: (state) => state.order,
  getCategoriesForSelect: (state) => state.categoriesSelect,
  getProducts: (state) => state.products,
  getImportedProducts: (state) => state.importedProducts,
  getImportedProductsTotal: (state) => state.importedProductsTotal,
  getCategory: (state) => state.category,
  getApp: (state) => state.app,
  getUser: (state) => state.userModel,
  getProduct: (state) => state.product,
  getVendorsForSelect: (state) => state.vendorsSelect,
  getSuppliersForSelect: (state) => state.suppliersSelect,
  getLangs: (state) => state.langs,
  getAttributes: (state) => state.attributes,
  getAttribute: (state) => state.attribute,
  getOptions: (state) => state.options,
  getOption: (state) => state.option,
  getOptionsSelect: (state) => state.optionsSelect,
  getAttributesSelect: (state) => state.attributesSelect,
  getProductAttributes: (state) => state.product.productAttributes,
  getImageFile: (state) => state.imageFile,
  isLoading: (state) => state.isLoading,
  getIpcFiles: (state) => state.files,
  getUserFiles: (state) => state.userFiles,
  getProductsSelect: (state) => state.productsSelect,
  getVendors: (state) => state.vendorList,
  getVendor: (state) => state.vendor,
  getSuppliers: (state) => state.suppliersList,
  getSupplier: (state) => state.supplier,
  getRolesSelect: (state) => state.rolesSelect,
  getTraining: (state) => state.training,
  getTrainings: (state) => state.trainings,
  getQuestions: (state) => state.questions,
  getBTrainings: (state) => state.bTrainings,
  getBTraining: (state) => state.bTraining,
  getBTrainingTypes: (state) => state.bTrainingTypes,
  getBTrainingCosts: (state) => state.bTrainingCosts,
  getBTrainingDetails: (state) => state.bTrainingsDetails,
  getPagination: (state) => state.pagination,
  getPaginationActivity: (state) => state.paginationActivity,
  getPaginationGlobalActivity: (state) => state.paginationGlobalActivity,
  getGroupsModel: (state) => state.groupsModel,
  getParentAttributes: (state) => state.parentAttributes,
  getGroups: (state) => state.groups,
  getGroup: (state) => state.group,
  getSlides: (state) => state.slides,
  getSlide: (state) => state.slide,
  getTextPages: (state) => state.textPages,
  getTextPage: (state) => state.textPage,
  getSetting: (state) => state.setting,
  getFeaturedProducts: (state) => state.featured_products,
  getToast: (state) => state.toast,
  getPending: (state) => state.isPending,
  getThreadsBySlug: (state) => state.threadsBySlug,
  getThreadsUsers: (state) => state.threadsUsers,
  getStats: (state) => state.stats,
  getTrainingResults: (state) => state.trainingResults,
  getBTrainingResults: (state) => state.bTrainingResults,
  getExternalTrainingResults: (state) => state.externalTrainingResults,
  getHunterResults: (state) => state.hunterResults,
  getNewsFeed: (state) => state.newsFeed,
  getNewsFeeds: (state) => state.newsFeeds,
  getWarehouses: (state) => state.warehouses,
  getWarehouseCount: (state) => state.warehouseCount,
  getSalesPromotions: (state) => state.salesPromotions,
  getMenuVisible: (state) => state.menuVisible,
  getHunterDetails: (state) => state.hunterDetails,
  getHunterDetail: (state) => state.hunterDetail,
  getDealers: (state) => state.dealers,
  getCustomer: (state) => state.customer,
  getAddPoints: (state) => state.points,
  getBoosterFields: (state) => state.boosterFields,
  getWholesalers: (state) => state.wholesalers,
  getUserOrders: (state) => state.userOrders,
  getBankStatementData: (state) => state.bankStatement,
  getLoginHistory: (state) => state.loginHistory,
  getLoginsHistory: (state) => state.loginsHistory,
  getDeletedUsers: (state) => state.detetedUsers,
  getPrices: (state) => state.prices,
  getPrice: (state) => state.price,
  getPriceCategories: (state) => state.priceCategories,
  getPriceList: (state) => state.priceList,
  getSinglePriceList: (state) => state.singlePriceList,
  getStaffs: (state) => state.staffs,
  getRequestStats: (state) => state.requestStats,
  getInactiveUsers: (state) => state.inactiveUsers,
  getAdminRole: (state) => state.admin_role,
  getComplaints: (state) => state.complaints,
  getComplaint: (state) => state.complaint,
  getCertificateTexts: (state) => state.certificateTexts,
  getUserActivity: (state) => state.activities,
  getGlobalActivity: (state) => state.activitiesGlobal,
  getActivityFilters: (state) => state.activityFilters,
  getCsNumbersToApprove: (state) => state.csNumberToApprove,
  getMessageIbanStatus: (state) => state.messageIbanStatus,
  getBoosters: (state) => state.boostersList,
  getInactiveBoosters: (state) => state.inactiveBoosters,
  getBoostersPreview: (state) => state.boostersPreview,
  getUserBoosterList: (state) => state.userBoosterSelect,
  getTrainingFilters: (state) => state.trainingFilters,
  getPromoFilters: (state) => state.promoFilters,
  getNewOrderItems: (state) => state.orderStats,
  getChecks: (state) => state.checks,
  getUserFilters: (state) => state.userFilters,
  getMenus: (state) => state.menuList,
  getMediaCategories: (state) => state.mediaCategories,
  getMedias: (state) => state.medias,
  getMediaPage: (state) => state.mediaPage,
  getMediaPageFiles: (state) => state.mediaPageFiles,
  getUberallList: (state) => state.uberallResults,
  getParentGroups: (state) => state.parentGroups,
  getWholesalerManagements: (state) => state.wholesalerManagements,
  getConnections: (state) => state.connections,
  getDefaultBanners: (state) => state.defaultBanners,
  getPointSettings: (state) => state.pointSettings,
  getInActiveCustomerNumbers: (state) => state.inActiveCustomerNumbers,
  getInitialUserRole: (state) => state.initialUserRole,
  getShopLimits: (state) => state.shopLimits,
  getTotalMediaFiles: (state) => state.totalMediaFiles,
  getMediaFiles: (state) => state.mediaFiles,
  getVoucherSelect: (state) => state.voucherSelect,
  getVouchers: (state) => state.vouchers,
  getBrochureCategories: (state) => state.brochureCategories,
  getBrochures: (state) => state.brochures,
  getBrochure: (state) => state.brochure,
  getTotalBrochureFiles: (state) => state.totalBrochureFiles,
  getBrochureFiles: (state) => state.brochureFiles,
  getFilterUser: (state) => state.filterUsers,
  getMainBoxes: (state) => state.mainBoxes,
  getMainBox: (state) => state.mainBox,
  getSurveys: (state) => state.surveys,
  getSurveysActivated: (state) => state.surveysActivated,
  getSurvey: (state) => state.survey,
  getSurveyGroups: (state) => state.survey_groups,
  getUserApps: (state) => state.userApps,
  getInitalParentAttributes: (state) => state.initialParentAttributes,
  getParentAttributesPending: (state) => state.parentAttributesPending,
  // tenancy part
  isLiteVersion: (state) => {
    return state.isLiteVersion
  },
  availableModules: (state) => {
    return [...state.availableModules] // Spread to extract array values and make it plain
  },
  tenancyEnabled: (state) => {
    return state.tenancyEnabled
  },
  tenantId: (state) => {
    return state.tenantId
  },
  bothRelationOptionDisabled: (state) => {
    return state.bothRelationOptionDisabled
  },
  fleetRelationOptionDisabled: (state) => {
    return state.fleetRelationOptionDisabled
  },
  noBussinesRelationOptionDisabled: (state) => {
    return state.noBussinesRelationOptionDisabled
  },
  countryToLocaleMap: (state) => {
    return state.countryToLocaleMap
  },
  getAboutList: (state) => state.abouts,
  getAbout: (state) => state.about,
  getContactServices: (state) => state.contactServices,
  getContactService: (state) => state.contactService,
  getPromotions: (state) => state.promotionList,
  getPromotion: (state) => state.promotion
}

export { getters }
