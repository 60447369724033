import ApiService from './api.service'

const GroupService = {
  async parentAttributes(id) {
    try {
      return await ApiService.get(`parent-attributes/${id}`)
    } catch (error) {
      return error.response
    }
  },
  async groups() {
    try {
      return await ApiService.get('groups')
    } catch (error) {
      return error.response
    }
  },
  async groupByPermission(permissionsId) {
    try {
      return await ApiService.get(`permissions/models/${permissionsId}`)
    } catch (error) {
      return error.response
    }
  },
  async updateGroupPermission(permissionsId, data) {
    try {
      return await ApiService.put(`permissions/models/${permissionsId}/update`, data)
    } catch (error) {
      return error.response
    }
  },
  async childrenGroups(id) {
    try {
      return await ApiService.get(`children-groups?parentId=${id}`)
    } catch (error) {
      return error.response
    }
  },

  async group(id) {
    try {
      return await ApiService.get('groups/' + id)
    } catch (error) {
      return error
    }
  },

  async storeGroup(data) {
    try {
      return await ApiService.post('groups', data)
    } catch (error) {
      return error
    }
  },

  async saveGroup(data, id) {
    try {
      return await ApiService.put('groups/' + id, data)
    } catch (error) {
      return error
    }
  },

  async saveUpdateParentAttribute(data) {
    try {
      return await ApiService.post('parent-attribute/assign', data)
    } catch (error) {
      return error
    }
  },

  async parentAttributeAsignSelector(data) {
    try {
      return await ApiService.post('parent-attribute/assign/selector', data)
    } catch (error) {
      return error
    }
  }
}

export { GroupService }
