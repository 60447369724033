<template>
  <router-view />
</template>

<script setup>
import 'vue3-toastify/dist/index.css'
import { ref, onMounted } from 'vue'
import { AppService } from '@/services/app.service'
import TokenService from '@/services/storage.service'

const firstLoad = ref(true)
setInterval(() => {
  if (firstLoad.value) {
    firstLoad.value = false
  } else {
    window.location.reload(true)
  }
}, 3600000)

onMounted(async () => {
  if (!TokenService.getLangs()) {
    const response = await AppService.tenantLanguages()
    const input = JSON.stringify(response.data)
    TokenService.saveLangs(input)
  }
})
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
